/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// IE CustomEvent polyfill
(function () {

  
  if ( typeof window.CustomEvent === "function" ) {
    return false;
  }
  console.log('hi');
  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

(function($) {


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        // add custom media query sizes for Foundation Interchange
        Foundation.Interchange.SPECIAL_QUERIES.smallretina = 'only screen and (min-width: 1px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min-resolution: 192dpi), only screen and (min-width: 1px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.mediumretina = 'only screen and (min-width: 641px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 641px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 641px) and (min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min-resolution: 192dpi), only screen and (min-width: 641px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.largeretina = 'only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1025px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min-resolution: 192dpi), only screen and (min-width: 1025px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.xlargeretina = 'only screen and (min-width: 1921px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1921px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min-resolution: 192dpi), only screen and (min-width: 1921px) and (min-resolution: 2dppx)';

        // init Foundation JavaScript
        $(document).foundation(); 

        // add plz pattern for Foundation Abide
        Foundation.Abide.defaults.patterns.plz = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;

        // set default Sticky size for Foundation Sticky
        Foundation.Sticky.defaults.stickyOn = 'large';

        // Mobile Menu
        Foundation.DropdownMenu.defaults.closeOnClick = true;

        // change body class on scroll
        $(window).scroll(function() {
          if ($(this).scrollTop() > 50) {
            $('body').addClass('solid-header');
          } else {
            $('body').removeClass('solid-header');
          }
        });

        // check for alternative website logo
        if ($( ".website-logo-alt" ).length) {
          $('body').addClass('alt-logo');
        }

        // add no-touch class for desktop browsers
        var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
        if (!touchsupport) { 
            document.documentElement.className += " no-touch ";
        }

        // show whatsApp Button
        if (Foundation.MediaQuery.is('small only')) {
          if( /windows phone|Android|webOS|iPhone|blackberry|Opera Mini/i.test(navigator.userAgent) ) {
           $('.whatsapp-share-button').css("display", 'inline-block');
          }
        }

        $('.teaser-headline.icon').each(function(index, element){
          var heading = $(element), word_array, last_word, first_part;
      
          word_array = heading.html().trim().split(/\s+/); // split on spaces
          last_word = word_array.pop();             // pop the last word
          first_part = word_array.join(' ');        // rejoin the first words together
      
          heading.html([first_part, ' <span class="iconspan">', last_word, '</span>'].join(''));          
        });        

        // Toggle menu
        $('[data-off-canvas]').on("opened.zf.offcanvas", function(e) {
          $('#toggle-menu').addClass('open');
          if ($(this).hasClass('full')) {
            $('body').addClass('full-off-canvas');
          }
          $('#main-header').addClass('toggle-menu-open');
          $('body').addClass('no-scroll toggle-menu-open');
          $('#toggle-menu-button').addClass('active');
        });
        $('[data-off-canvas]').on("closed.zf.offcanvas", function(e) {
          $('#toggle-menu').removeClass('open');
          $('#main-header').removeClass('toggle-menu-open');
          $('body').removeClass('no-scroll toggle-menu-open');
          $('#toggle-menu-button').removeClass('active');          
        });

        $('#toggle-menu .menu-item-has-children > a').click(function(){
           var i = 0;
           var parent = $(this).parent();
           $('#toggle-menu ul > li').each(function(){
             if($('#toggle-menu ul > li').index(parent)!==i){
               $(this).removeClass('open');
               $(this).find('*').removeClass('open');
             }
             i++;
           });
           $(this).parent().toggleClass('open');
           $(this).toggleClass('open');
           $(this).siblings('.foldable-content').toggleClass('open');
           return false;
        });     
        
        $('.off-canvas').on('scroll', function () {
          if ($(this).scrollTop() > 0) {
            $('#main-header').addClass('toggle-menu-scrolled');
          } else {
            $('#main-header').removeClass('toggle-menu-scrolled');
          }
        });

        // 23 GlueFrame for 23 video player see: https://github.com/23/glueframe
        var GlueFrame = function(iframe, appName) {
        
            var $this = this;
        
            // GlueFrame version
            $this.glueframe = "1.1.3";
        
            // Allow posting messages only to the domain of the app
            var _domain = (""+iframe.src).split("/").slice(0,3).join("/");
        
            // Determine method of communication with iframe
            var _method = (function() {
                if (_domain === (""+window.location).split("/").slice(0,3).join("/") ) {
                    return "object";
                } else if (typeof window.postMessage !== "undefined") {
                    return "post";
                } else {
                    return "none";
                }
            })();
        
            // Poll the iframe until the app is bootstrapped
            $this.ready = false;
            var _readyInterval = window.setInterval(function(){
                if (!this.ready && _method === "object") {
                    if (iframe.contentWindow[appName] && iframe.contentWindow[appName].bootstrapped) {
                        $this.ready = true;
                        window.clearInterval(_readyInterval);
                        _processQueue();
                    }
                } else if (!this.ready && _method === "post") {
                    $this.get("bootstrapped", function(bootstrapped){
                        if (bootstrapped) {
                            $this.ready = true;
                            window.clearInterval(_readyInterval);
                            _processQueue();
                        }
                    }, true);
                }
            }, 100);
        
            $this.glueFrameId = Math.floor((new Date()).getTime()*Math.random());
            var _callbackCount = 0;
            var _callbacks = {};
        
            // Store callback functions in the parent window
            var _registerCallback = function(callback, requireCallback) {
                var callbackIdentifier = $this.glueFrameId + "_" + (++_callbackCount);
                if (typeof callback === "function") {
                    _callbacks[callbackIdentifier] = callback;
                } else if (requireCallback) {
                    throw "GlueFrame: Callback not registered correctly.";
                }
                return callbackIdentifier;
            };
        
            // Queue up method calls until app is ready
            var _queue = [];
            var _addToQueue = function(method, args) {
                _queue.push({method: method, args: args});
            };
        
            // Loop through queue when app is ready
            var _processQueue = function() {
                for (var i = 0; i < _queue.length; i += 1) {
                    var queueItem = _queue[i];
                    queueItem.method.apply(null, queueItem.args);
                }
                _queue = [];
                $this.set("queuedEventsProcessed", true);
            };
        
            $this.get = function(prop, callback, force) {
                if (!$this.ready && !force) {
                    _addToQueue($this.get, [prop, callback]);
                    return;
                }
                var cbId = _registerCallback(callback, true);
                if (_method === "object") {
                    var value = iframe.contentWindow[appName].get.apply(null, [prop]);
                    if (typeof _callbacks[cbId] !== "undefined") {
                        _callbacks[cbId].apply(null, [value]);
                    }
                } else if (_method === "post") {
                    var messageObject = {f: "get", args: [prop], cbId: cbId};
                    iframe.contentWindow.postMessage( JSON.stringify(messageObject), force ? "*" : _domain );
                }
            };
        
            $this.set = function(prop, val, callback) {
                if (!$this.ready) {
                    _addToQueue($this.set, [prop, val, callback]);
                    return;
                }
                var cbId = _registerCallback(callback, false);
                if (_method === "object") {
                    var value = iframe.contentWindow[appName].set.apply(null, [prop, val]);
                    if (typeof _callbacks[cbId] !== "undefined") {
                        _callbacks[cbId].apply(null, [value]);
                    }
                } else if (_method === "post") {
                    var messageObject = {f: "set", args: [prop, val], cbId: cbId};
                    iframe.contentWindow.postMessage( JSON.stringify(messageObject), _domain );
                }
            };
        
            $this.bind = function(event, callback, triggerQueue) {
                var triggerQueue = triggerQueue || false;
                if (!$this.ready) {
                    _addToQueue($this.bind, [event, callback, true]);
                    return;
                }
                var cbId = _registerCallback(callback, true);
                if (_method === "object") {
                    iframe.contentWindow[appName].bind.apply(null, [event, callback, triggerQueue]);
                } else if (_method === "post") {
                    var messageObject = {f: "bind", args: [event], cbId: cbId, triggerQueue: triggerQueue};
                    iframe.contentWindow.postMessage( JSON.stringify(messageObject), _domain );
                }
            };
        
            $this.fire = function(event, obj) {
                if (!$this.ready) {
                    _addToQueue($this.fire, [event, obj]);
                    return;
                }
                if (_method === "object") {
                    return iframe.contentWindow[appName].fire.apply(null, [event, obj]);
                } else if (_method === "post") {
                    var messageObject = {f: "fire", args: [event, obj]};
                    iframe.contentWindow.postMessage( JSON.stringify(messageObject), _domain );
                }
            };
        
            // Remove event listeners, callbacks and intervals
            $this.destroy = function(){
                if (window.addEventListener) {
                    window.removeEventListener("message", _receiveMessage, false);
                } else {
                    window.detachEvent("onmessage", _receiveMessage);
                }
                window.clearInterval(_readyInterval);
                _callbacks = {};
            };
        
            // Parse messages received from iframe
            var _receiveMessage = function(e) {
              if (e.origin === _domain) {
                    var data;
                    try {
                        data = JSON.parse(e.data);
                    }catch(e){}
                    if (typeof data !== "undefined" && typeof data.cbId !== "undefined" && typeof _callbacks[data.cbId] === "function") {
                        _callbacks[data.cbId].apply(null, [data.a, data.b]);
                    }
                }
            };
        
            // Listen for message events if need
            if (window.addEventListener) {
                window.addEventListener("message", _receiveMessage, false);
            } else {
                window.attachEvent("onmessage", _receiveMessage);
            }
        
        };
        if(typeof(window.onGlueFrameAvailable)==='function') {
            window.onGlueFrameAvailable(GlueFrame);
        } 
        
        if (typeof module !== 'undefined' && module.exports) {
            module.exports = GlueFrame;
        }

        // Post head section start 23 video player
        $('.head-section .video-wrap').click(function(e){
          var videoWrap = $(this);
          var videoFrame = $(this).find('iframe');
          var videoUrl = videoFrame.prop('src');
          var videoPlayer = new GlueFrame(videoFrame[0], "Player");
          videoPlayer.set("playing", true);
          //console.log('video src is playing');  
          var headSection = $(this).closest('.head-section');
          setTimeout(function() {
              videoWrap.addClass('clicked');
              headSection.addClass('video-clicked');
          }, 500);                            
        });       

        $('.reveal').on("open.zf.reveal", function(e) {
          var videoWrap = $(this);
          var videoFrame = $(this).find('iframe');
          var videoUrl = videoFrame.prop('src');
          var videoPlayer = new GlueFrame(videoFrame[0], "Player");
          videoPlayer.set("playing", true);                
        });
        $('.reveal').on("closed.zf.reveal", function(e) {
          var videoWrap = $(this);
          var videoFrame = $(this).find('iframe');
          var videoUrl = videoFrame.prop('src');
          var videoPlayer = new GlueFrame(videoFrame[0], "Player");
          videoPlayer.set("playing", false);                
        });

        // Share Button popups
        $('.share-button').click(function(e){
          if (this.classList.contains("share-popup")) { 

              e.preventDefault();

              var $this = $(this),
                  href  = $this.attr('href'),
                  width       = 400,
                  height      = 500,
                  left        = (window.innerWidth/2) - width/2,
                  top         = (window.innerHeight/2) - height/2,
                  config      = 'status = no,height = '+width+',width  = '+height+',resizable = yes,screenY  = '+top+',screenX = '+left+',toolbar = no,menubar = no,scrollbars = no,location = no,directories = no';
              
              window.open(href, '', config);
          }           
        });

        // Button popup
        $('.button-popup').on("open.zf.reveal", function(e) {
          var scrollTop = $(window).scrollTop();
          if ($(document).height() > $(window).height()) {
            $("html")
              .css("top", -scrollTop);
          }     

          $('body').addClass('button-popup-open');

          $(this).find('img').each(function() {
            var timeout = 0; // no delay
            var $img = $(this);
            var imgurl = $img.attr('src');
            imgurl = imgurl + '?' + Math.random();

            $img.removeAttr('src');
            setTimeout(function() {
                $img.attr('src', imgurl);
            }, timeout);
          });          
        });
        $('.button-popup').on("closed.zf.reveal", function(e) {
          var scrollTop = parseInt($("html").css("top"));
          if ($(document).height() > $(window).height()) {
            $("html")
              .css("top", "");
            $(window).scrollTop(-scrollTop);
          }          
          $('body').removeClass('button-popup-open');                 
        }); 

        // WooCommerce
        $('body').on( 'added_to_cart', function(){
          $('.woo-popup, .woo-popup-wrapper').show();
        });
        $('.woo-popup .close-popup').on( 'click', function(){
          $('.woo-popup, .woo-popup-wrapper').hide();
        });

        var inputQuantity = [];
        $(".input-text.qty.text").each(function(i) {
          inputQuantity[i]=this.defaultValue;
           $(this).data("idx",i); // save this field's index to access later
        });
        $(".input-text.qty.text").on("keyup", function (e) {
          var $field = $(this),
              val=this.value,
              $thisIndex=parseInt($field.data("idx"),10); // retrieve the index
            //  $field.is(":invalid") is for Safari, it must be the last to not error in IE8
          if (this.validity && this.validity.badInput || isNaN(val) || $field.is(":invalid") ) {
              this.value = inputQuantity[$thisIndex];
              return;
          } 
          if (val.length > 2) {
            val=val.slice(0, 2);
            $field.val(val);
          }
          inputQuantity[$thisIndex]=val;
        });   

        $('.single_variation_wrap').on('show_variation', function ( event, variation ) {
          setTimeout(function() {
            //console.log( variation );
            var $currentVariationWrap = $('.variation_id[value=' + variation.variation_id + ']').closest('.single_variation_wrap');
            if (variation.stock_status === 'notorderable' || variation.stock_status === 'outofstock') {
              $currentVariationWrap.find( '.single_add_to_cart_button' ).addClass( 'hide' );
              $(variation.availability_html).insertAfter($currentVariationWrap.find( '.single_add_to_cart_button' ));
              if (variation.stock_status === 'outofstock') {
                $currentVariationWrap.find( '.not-orderable.stock-notice' ).remove();
              }
              if (variation.stock_status === 'notorderable') {
                $currentVariationWrap.find( '.out-of-stock.stock-notice' ).remove();
              }
            } else {
              $currentVariationWrap.find( '.single_add_to_cart_button' ).removeClass( 'hide' );
              $currentVariationWrap.find( '.stock-notice' ).remove();
            }
          }, 0);
        } );

        // Smooth scrolling
        if(window.location.hash) {
          $('html, body').animate({
              scrollTop: $(window.location.hash).offset().top - $('#main-header').height() + 'px'
          }, 1000);
        }         

        // Scrolldown Button scrolling
        $('.scrolldown-button').click(function(){
          var nextSection = $(this).closest('section').next();

          $('html, body').animate({
              scrollTop: nextSection.offset().top
          }, 1000);            
        });       

       

        if($('.timeline-quiz-new').length)
        {
          $('.timeline-quiz-new').eq(0).nextAll('.timeline-content-section').hide();
        }

        // Flip animation
        $('.flip-item.flippable').click(function(){
          $(this).toggleClass('flipped');
        });


        $('.quiz-item .quiz-item-content-item').click(function(){
          $(this).addClass('clicked');
          var $currentQuiz = $(this).closest('.quiz-items-wrap');
          var $currentQuizSolution = $currentQuiz.find('.quiz-solution');
          var $currentQuizItem = $(this).closest('.quiz-item');
          var $currentFlipItem = $currentQuizItem.find('.flip-item');
          var $currentContentItems = $currentQuizItem.find('.quiz-item-content-item');
          var $currentContentItemsCorrect = $currentQuizItem.find('.quiz-item-content-item.is-correct');
          var $currentContentItemsClicked = $currentQuizItem.find('.quiz-item-content-item.clicked');
          var $currentContentItemsCorrectClicked = $currentQuizItem.find('.quiz-item-content-item.is-correct.clicked');

          var timeline = false;
          var timelineQuiz = false;

          if($(this).closest('.timeline-quiz-new').length)
          {
            timeline = true;
            timelineQuiz = $(this).closest('.timeline-quiz-new');
          }

          if ($currentContentItemsCorrect.length === $currentContentItemsClicked.length) {
            $currentQuizItem.addClass('solved-quiz-item');

            if ($currentContentItemsCorrect.length === $currentContentItemsCorrectClicked.length) {
              $currentQuizItem.addClass('correct-solved-quiz-item');
            }

            function checkForIEflipped() {
              var userAgent = window.navigator.userAgent;
              var ieReg = /msie|Trident.*rv[ :]*11\./gi;
              var ie = ieReg.test(userAgent);

              if(ie) {
                $currentFlipItem.addClass('flippable').addClass('flipped-ie');

              }
              else {
                // Flip animation
                $currentFlipItem.addClass('flippable').addClass('flipped');
              }
            }
            checkForIEflipped();


            if(timeline)
            {
              timelineQuiz.addClass('timeline-quiz-done');
              timelineQuiz.removeClass('timeline-quiz-new');
              
              $('.timeline-content-section').each(function( index )
              {
                if($(this).is(":hidden"))
                {
                  $(this).fadeIn( 500 );
                }
                if($(this).hasClass('timeline-quiz-new'))
                {
                  return false;
                }
              });

              timelineQuiz.nextUntil('.timeline-quiz-new').fadeIn( 500 );
              timelineQuiz.next('.timeline-quiz-new' ).fadeIn( 500 );
            }
            
            $currentFlipItem.click(function(){
              $(this).toggleClass('flipped');
            });    

            var $currentQuizItems = $currentQuiz.find('.quiz-item');
            var $currentSolvedQuizItems = $currentQuiz.find('.quiz-item.solved-quiz-item');

            if ($currentSolvedQuizItems.length === $currentQuizItems.length) {
              $currentQuizSolution.removeClass('hide');

              //var correctItems = $currentQuiz.find('.quiz-item-content-item.is-correct.clicked').length;
              //var possibleItems = $currentQuiz.find('.quiz-item-content-item.is-correct').length;

              var correctItems = $currentQuiz.find('.correct-solved-quiz-item').length;
              var possibleItems = $currentQuizItems.length;
            
              var $currentNumCorrectAnswers = $currentQuiz.find('.num-correct-answers');
              $currentNumCorrectAnswers.html(correctItems);

              if (correctItems === 0 && $('.quiz-solution-content-wrap').length === 0) {
                $currentQuiz.find('.quiz-solution-text-wrap').addClass('hide');
              }
              if (correctItems > 0 && correctItems <= (possibleItems * 0.5)) {
                $currentQuiz.find('.quiz-solution-text-50').removeClass('hide');
              }  
              if (correctItems > (possibleItems * 0.5) && correctItems <= (possibleItems * 0.75)) {
                $currentQuiz.find('.quiz-solution-text-75').removeClass('hide');
              }              
              if (correctItems > (possibleItems * 0.75) && correctItems <= (possibleItems * 1)) {
                $currentQuiz.find('.quiz-solution-text-100').removeClass('hide');
              }

              $currentQuiz.find('video').each(function(){
                $(this).get(0).play();
                console.log('video is playing');
              });
              $currentQuiz.find('.embed-container').each(function(){
                var videoFrame = $(this).find('iframe');
                var videoUrl = videoFrame.prop('src');
                videoFrame[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                console.log('video src is playing');
              });

            }
          }
          
        });

        // Contact Form 7 scroll on submit
        $(".wpcf7-submit").click(function() {
          $('html,body').animate({
                scrollTop: $(".wpcf7").offset().top - 90},
              'fast');
        });

        // Search
        function initSearch() {
          $('.top-bar-right .search-form .search-button').on('click', function(e) {
            e.preventDefault();
            $('body').addClass('search-bar-open');
            $('.navbar-nav').addClass('search-bar-active');
            $('.top-bar-right .search-form .search-input').focus();
            $('.top-bar-right .search-form .search-input').keypress(function (e) {
              if (e.which === 13) {
                $(this).parents('form').submit();
                return false;
              }
            });
          });
          $('.top-bar-right .search-form .search-close').on('click', function(e) {
            e.preventDefault();
            $('.top-bar-right .search-input').blur();
            $('body').removeClass('search-bar-open');
            $('.navbar-nav').removeClass('search-bar-active');
          });
          $('.toggle-menu-overlay').on('click', function(e) {
            e.preventDefault();
            $('.top-bar-right .search-form .search-input').blur();
            $('body').removeClass('search-bar-open');
            $('.navbar-nav').removeClass('search-bar-active');
          });  

          $('.search-toggle-menu .search-form .search-button').on('click', function(e) {
            e.preventDefault();
            $(this).parents('form').submit();
            return false;
          });           
          $('.search-toggle-menu .search-input').keypress(function (e) {
            if (e.which === 13) {
              $(this).parents('form').submit();
              return false;
            }
          });                           
        }

        initSearch(); 

        // Cookie functions
        function createCookie(name, value, days) {
          var expires = "";
            if (days) {
              var date = new Date();
              date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
              expires = "; expires=" + date.toGMTString();
            } else {
              expires = "";
            }
            document.cookie = name + "=" + value + expires + "; path=/";
        }
        
        function readCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                  c = c.substring(1, c.length);
                }
                if (c.indexOf(nameEQ) === 0) {
                  return c.substring(nameEQ.length, c.length);
                }
            }
            return null;
        }
        
        function eraseCookie(name) {
            createCookie(name, "", -1);
        }


        // If cookie-notice cookie is not set, show .new-cookie-notice
        if ((!readCookie('cookie-notice')) && (!$('body').hasClass('iframe'))) {
            $('.new-cookie-notice').show();
        } else {
            $('.new-cookie-notice').hide();
        }
    
        // Add the event that closes the popup and sets the cookie to not show again until 3650 days have passed.
        $('#close-cookie-notice').click(function(){
          $(".new-cookie-notice").slideUp(150);
          createCookie('cookie-notice', true, 3650);
          return false;
        });

          // caption accordion
          $(".set > .name").on("click", function() {
              if ($(this).hasClass("active")) {
                  $(this).removeClass("active");
                  $(this)
                      .siblings(".text-content")
                      .slideUp(200);
                  $(this).find(".arrow-down").removeClass('turn-arrow');
              }
              else {

                  $(".set > .name").removeClass("active");
                  $(this).addClass("active");
                  $(this)
                      .siblings(".text-content")
                      .slideDown(200);
                  $(this).find(".arrow-down").addClass('turn-arrow');
              }
          });

          $(".set-readmore > .name").on("click", function() {
              if ($(this).hasClass("active")) {
                  $(this).removeClass("active");
                $(this).text("Mehr Anzeigen");
                  $(this)
                      .siblings(".readmore-content")
                      .slideUp(200);
                  $(this).find(".arrow-down").removeClass('turn-arrow');
              }
              else {

                  $(".set-readmore > .name").removeClass("active");
                  $(this).addClass("active");
                $(this).text("Weniger Anzeigen");
                  $(this)
                      .siblings(".readmore-content")
                      .slideDown(200);
                  $(this).find(".arrow-down").addClass('turn-arrow');
              }
          });


        // info-box-section cookie
        if ((!readCookie('info-box-section-closed')) && (!$('body').hasClass('iframe'))) {
            $('.info-box-section').show();
        } else {
            $('.info-box-section').hide();
        }

        $( ".close-section-button" ).click(function() {
          var $closeSection = $(this).closest('section');
          $closeSection.slideUp(300);
          createCookie('info-box-section-closed', true, 3650);
          return false;          
        });                     

        // Load BMI results
        function ajax_load_bmi_results (bmiModuleIndex, age, type, layoutIndex, subModuleIndex) {

          $.ajax({
            url : SiteParameters.ajax_url,
            type : 'GET',
            dataType: 'html',
            data: ({
              action: 'load_bmi_results',
              currentage: age,
              currentresulttype: type,
              currentlayoutindex: layoutIndex,
              currentsubmoduleindex: subModuleIndex,
              currentpostid: SiteParameters.post_id
            }),      
            beforeSend: function (xhr) {
              
            },
            success: function(data){
              if(data) { 
                var parsedData = jQuery.parseJSON(data);
                if (parsedData.output){
                  $('#bmi-wrap-' + bmiModuleIndex + ' .bmi-result-text-wrap').html(parsedData.output).removeClass('hide');
                }              
              }
            },
            error: function(data) {
              console.log("Error: " + data);
              return false;
            },
            complete: function() {    
              //console.log("Complete");                         
            }
          });
        }        
        // BMI calculation
        function calculateBMI(bmiModuleIndex,currentGender,currentAge,currentHeight,currentWeight) {
          
          var gender = currentGender;
          var age = parseInt(currentAge);
          var weight = currentWeight;
          var height = parseInt(currentHeight) / 100;
          var bmi = weight / (height * height);
          //var roundedBMIString = bmi.toFixed(1);
          var roundedBMIString = (Math.round(bmi * 100)/100).toFixed(1);
          bmi = Number(roundedBMIString);
          console.log("bmi: " + bmi);
       
          var type = 'normal';
          var resultContent = 'Normalgewicht';
          var rowIndex = $('#bmi-wrap-' + bmiModuleIndex).attr('data-bmi-module-row-index');
          var subRowIndex = $('#bmi-wrap-' + bmiModuleIndex).attr('data-bmi-module-sub-row-index');   

          if (gender === 'female') {
            if (age === 8) {
                if (bmi < 12.2) {
                        type = 'severely_underweight';
                } else if (bmi <= 13.2) {
                        type = 'underweight';
                } else if (bmi <= 18.7) {
                        type = 'normal';
                } else if (bmi <= 22.3) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 9) {
                if (bmi < 13.0) {
                        type = 'severely_underweight';
                } else if (bmi <= 13.7) {
                        type = 'underweight';
                } else if (bmi <= 19.7) {
                        type = 'normal';
                } else if (bmi <= 23.4) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 10) {
                if (bmi < 13.4) {
                        type = 'severely_underweight';
                } else if (bmi <= 14.2) {
                        type = 'underweight';
                } else if (bmi <= 20.6) {
                        type = 'normal';
                } else if (bmi <= 23.4) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 11) {
                if (bmi < 13.8) {
                        type = 'severely_underweight';
                } else if (bmi <= 14.6) {
                        type = 'underweight';
                } else if (bmi <= 20.7) {
                        type = 'normal';
                } else if (bmi <= 22.9) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 12) {
                if (bmi < 14.8) {
                        type = 'severely_underweight';
                } else if (bmi <= 15.6) {
                        type = 'underweight';
                } else if (bmi <= 21.4) {
                        type = 'normal';
                } else if (bmi <= 23.4) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 13) {
                if (bmi < 15.2) {
                        type = 'severely_underweight';
                } else if (bmi <= 16.0) {
                        type = 'underweight';
                } else if (bmi <= 22.0) {
                        type = 'normal';
                } else if (bmi <= 24.4) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 14) {
                if (bmi < 16.2) {
                        type = 'severely_underweight';
                } else if (bmi <= 17.0) {
                        type = 'underweight';
                } else if (bmi <= 23.1) {
                        type = 'normal';
                } else if (bmi <= 26.0) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 15) {
                if (bmi < 16.9) {
                        type = 'severely_underweight';
                } else if (bmi <= 17.6) {
                        type = 'underweight';
                } else if (bmi <= 23.1) {
                        type = 'normal';
                } else if (bmi <= 27.6) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 16) {
                if (bmi < 16.9) {
                        type = 'severely_underweight';
                } else if (bmi <= 17.8) {
                        type = 'underweight';
                } else if (bmi <= 22.7) {
                        type = 'normal';
                } else if (bmi <= 24.2) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 17) {
                if (bmi < 17.1) {
                        type = 'severely_underweight';
                } else if (bmi <= 17.8) {
                        type = 'underweight';
                } else if (bmi <= 23.3) {
                        type = 'normal';
                } else if (bmi <= 25.7) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 18) {
                if (bmi < 17.6) {
                        type = 'severely_underweight';
                } else if (bmi <= 18.3) {
                        type = 'underweight';
                } else if (bmi <= 23.4) {
                        type = 'normal';
                } else if (bmi <= 25.0) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age > 18) {
                if (bmi < 19.0) {
                        type = 'underweight';
                } else if (bmi <= 25.0) {
                        type = 'normal';
                } else if (bmi <= 31.0) {
                        type = 'overweight';
                } else if (bmi <= 41.0) {
                        type = 'obese';
                } else {
                        type = 'severely_obese';
                }              
            }            
          } else if (gender === 'male') {
            if (age === 8) {
                if (bmi < 12.5) {
                        type = 'severely_underweight';
                } else if (bmi <= 14.2) {
                        type = 'underweight';
                } else if (bmi <= 19.2) {
                        type = 'normal';
                } else if (bmi <= 22.6) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 9) {
                if (bmi < 12.8) {
                        type = 'severely_underweight';
                } else if (bmi <= 13.7) {
                        type = 'underweight';
                } else if (bmi <= 19.3) {
                        type = 'normal';
                } else if (bmi <= 21.6) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 10) {
                if (bmi < 13.9) {
                        type = 'severely_underweight';
                } else if (bmi <= 14.6) {
                        type = 'underweight';
                } else if (bmi <= 21.3) {
                        type = 'normal';
                } else if (bmi <= 25.0) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 11) {
                if (bmi < 14.0) {
                        type = 'severely_underweight';
                } else if (bmi <= 14.3) {
                        type = 'underweight';
                } else if (bmi <= 21.1) {
                        type = 'normal';
                } else if (bmi <= 23.1) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 12) {
                if (bmi < 14.6) {
                        type = 'severely_underweight';
                } else if (bmi <= 14.8) {
                        type = 'underweight';
                } else if (bmi <= 21.9) {
                        type = 'normal';
                } else if (bmi <= 24.8) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 13) {
                if (bmi < 15.6) {
                        type = 'severely_underweight';
                } else if (bmi <= 16.2) {
                        type = 'underweight';
                } else if (bmi <= 21.6) {
                        type = 'normal';
                } else if (bmi <= 24.5) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 14) {
                if (bmi < 16.1) {
                        type = 'severely_underweight';
                } else if (bmi <= 16.7) {
                        type = 'underweight';
                } else if (bmi <= 22.5) {
                        type = 'normal';
                } else if (bmi <= 25.7) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 15) {
                if (bmi < 17.0) {
                        type = 'severely_underweight';
                } else if (bmi <= 17.8) {
                        type = 'underweight';
                } else if (bmi <= 23.0) {
                        type = 'normal';
                } else if (bmi <= 25.9) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 16) {
                if (bmi < 17.8) {
                        type = 'severely_underweight';
                } else if (bmi <= 18.5) {
                        type = 'underweight';
                } else if (bmi <= 23.6) {
                        type = 'normal';
                } else if (bmi <= 26.0) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 17) {
                if (bmi < 17.6) {
                        type = 'severely_underweight';
                } else if (bmi <= 18.6) {
                        type = 'underweight';
                } else if (bmi <= 23.6) {
                        type = 'normal';
                } else if (bmi <= 25.8) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age === 18) {
                if (bmi < 17.6) {
                        type = 'severely_underweight';
                } else if (bmi <= 18.6) {
                        type = 'underweight';
                } else if (bmi <= 23.9) {
                        type = 'normal';
                } else if (bmi <= 26.8) {
                        type = 'overweight';
                } else {
                        type = 'severely_overweight';
                }              
            } else if (age > 18) {
                if (bmi < 20.0) {
                        type = 'underweight';
                } else if (bmi <= 26.0) {
                        type = 'normal';
                } else if (bmi <= 31.0) {
                        type = 'overweight';
                } else if (bmi <= 41.0) {
                        type = 'obese';
                } else {
                        type = 'severely_obese';
                }              
            }            
          }

          if (type === 'severely_underweight') {
            resultContent = 'Starkes Untergewicht';              
          } else if (type === 'underweight') {
            resultContent = 'Untergewicht';
          } else if (type === 'overweight') {
            resultContent = 'Übergewicht';
          } else if (type === 'severely_overweight') {
            resultContent = 'Starkes Übergewicht';
          } else if (type === 'obese') {
            resultContent = 'Adipositas';
          } else if (type === 'severely_obese') {
            resultContent = 'Starke Adipositas';
          }

          var resultBMI = bmi.toFixed(1).replace(".", ",");
          if (age > 18) {
            resultBMI = bmi.toFixed(0);
          }          
          $('#bmi-wrap-' + bmiModuleIndex + ' .bmi-result-number').html(resultBMI);
          $('#bmi-wrap-' + bmiModuleIndex + ' .bmi-result-content').html(resultContent).addClass('active');
          $('#bmi-wrap-' + bmiModuleIndex + ' .bmi-result-arrow').removeClass('hide');
          ajax_load_bmi_results(bmiModuleIndex, age, type, rowIndex, subRowIndex);
        }   


        $('.bmi-wrap').each(function(){
          var $this = $(this);
          var bmiModuleIndex = $this.attr('data-bmi-module-index');
          var currentGender = '';

          var $currentGenderControl = $this.find('input[name=gender]:radio');
          var $currentAgeControl = $this.find('.bmi-age-slider');
          var $currentHeightControl = $this.find('.bmi-height-slider');
          var $currentWeightControl = $this.find('.bmi-weight-slider');

          var initAge = $currentAgeControl.attr('data-initial-start');
          var initHeight = $currentHeightControl.attr('data-initial-start');
          var initWeight = $currentWeightControl.attr('data-initial-start');          

          var currentAge = initAge;
          var currentHeight = initHeight;
          var currentWeight = initWeight;

          $currentGenderControl.on('change', function() {
            currentGender = $(this).val();
            //console.log("currentGender: " + currentGender);
            if (currentGender !== '' && !isNaN(currentAge) && !isNaN(currentHeight) && !isNaN(currentWeight)) {
              calculateBMI(bmiModuleIndex,currentGender,currentAge,currentHeight,currentWeight);
            }
          });   
          $currentAgeControl.on('changed.zf.slider', function(e){
            currentAge = $(this).find('.slider-handle').attr('aria-valuenow');
            //console.log("currentAge: " + currentAge);
            if (currentGender !== '' && !isNaN(currentAge) && !isNaN(currentHeight) && !isNaN(currentWeight)) {
              calculateBMI(bmiModuleIndex,currentGender,currentAge,currentHeight,currentWeight);
             }
          });
          $currentHeightControl.on('changed.zf.slider', function(e){
            currentHeight = $(this).find('.slider-handle').attr('aria-valuenow');
            //console.log("currentHeight: " + currentHeight);
            if (currentGender !== '' && !isNaN(currentAge) && !isNaN(currentHeight) && !isNaN(currentWeight)) {
              calculateBMI(bmiModuleIndex,currentGender,currentAge,currentHeight,currentWeight);
            }
          });
          $currentWeightControl.on('changed.zf.slider', function(e){
            currentWeight = $(this).find('.slider-handle').attr('aria-valuenow');
            //console.log("currentWeight: " + currentWeight);
            if (currentGender !== '' && !isNaN(currentAge) && !isNaN(currentHeight) && !isNaN(currentWeight)) {
              calculateBMI(bmiModuleIndex,currentGender,currentAge,currentHeight,currentWeight);
            }
          }); 

          $this.find('input[type=number]').keypress(function (e) {
            if (e.which === 13) {
              $(this).blur();
              return false;
            }
          });

          //$this.find('.slider-handle').mousedown(function() {
          //  var $currentBMIBox = $(this).closest('.bmi-box');
          //  $currentBMIBox.find('.init-dashes').hide();
          //  $currentBMIBox.find('.bmi-box-output.hide').removeClass('hide').show();       
          //}); 
          //$this.find('.init-dashes').click(function() {
          //  var $currentBMIBox = $(this).closest('.bmi-box');
          //  $currentBMIBox.find('.init-dashes').hide();
          //  $currentBMIBox.find('.bmi-box-output.hide').removeClass('hide').show();       
          //});                                                                 
        });         

        // recipe calculation
        $( ".control-ingredients-button" ).click(function() {
          var $portionsElem = $(this).closest('.control-ingredients').find('.control-ingredients-portions');
          var $textElem = $(this).closest('.control-ingredients').find('.control-ingredients-text');
          var currentPortions = parseInt($portionsElem.attr('data-current-portions'),10);
          if ($(this).hasClass('up')) {
              currentPortions = currentPortions + 1;
              $portionsElem.attr('data-current-portions', currentPortions);
              $portionsElem.html(currentPortions);
          } else {
            if (currentPortions > 1) {
              currentPortions = currentPortions - 1;
              $portionsElem.attr('data-current-portions', currentPortions);
              $portionsElem.html(currentPortions);
            } else {
              currentPortions = 1;
              $portionsElem.attr('data-current-portions', currentPortions);
              $portionsElem.html(currentPortions);              
            }
          }  

          if (currentPortions === 1) {
            $textElem.html(' Portion');
          } else {
            $textElem.html(' Portionen');
          } 

          $('.ingredient-item-amount').each(function(){
            var $this = $(this);
            var currentAmount =  parseFloat($this.attr('data-ingredient-item-amount'));
            var modifiedAmount = currentAmount * currentPortions;
            modifiedAmount = String(modifiedAmount);
            var resultAmount = modifiedAmount.replace(".", ",");
            $this.html(resultAmount);
          });                          
        }); 

        // Forms
        $('.standard-form').each(function(idx, item) {
          var $this = $(this);
          var formIndex = $this.attr('data-form-index');
          var formType = $this.attr('data-form-type');

          $this.bind("submit",function(e) {
            e.preventDefault();
            //console.log("submit intercepted");
            return false;
          });

          $this.bind("invalid.zf.abide", function(ev,elem) {
            //console.log("Field "+elem.attr('name')+" is invalid");
            var errorCount = $this.find('[data-invalid]').length;       
          });

          $this.bind("valid.zf.abide", function(ev,elem) {
            //console.log("Field "+elem.attr('name')+" is valid");
            var errorCount = $this.find('[data-invalid]').length;     
          });
          $this.bind("forminvalid.zf.abide", function(e,target) {
            //console.log("form is invalid");
          });

          $this.on("formvalid.zf.abide", function(ev,frm) {
            var formData;

            formData = 'action=standardFormAction&post_id=' + SiteParameters.post_id +'&form_index=' + formIndex +'&form_type=' + formType + '&' + $this.serialize();
            $.ajax({
              url: SiteParameters.ajax_url,
              type: 'POST',
              dataType: 'JSON',
              data: formData,
              beforeSend: function () {
                //console.log('beforeSend');               
              },
              error: function () {
                console.log('error function fired');
              },
              success: function (data) {
                if (data.status === 'success') {
                  $('.standard-form-' + formIndex).hide();
                  $('.standard-form-' + formIndex).next('#thank-you-message').fadeIn(300);                
                  $(window).trigger('resize');
                } else {
                  console.log('success error');
                }
              },
              complete: function() {
                //console.log('Complete');
              }
            });
            return false; 
          });
        });   

        // Login Forms
        $('.login-form').each(function(idx, item) {
          var $this = $(this);
          var formIndex = $this.attr('data-form-index');
          var formType = $this.attr('data-form-type');

          $this.bind("submit",function(e) {
            e.preventDefault();
            //console.log("submit intercepted");
            return false;
          });

          $this.bind("invalid.zf.abide", function(ev,elem) {
            //console.log("Field "+elem.attr('name')+" is invalid");
            var errorCount = $this.find('[data-invalid]').length;       
          });

          $this.bind("valid.zf.abide", function(ev,elem) {
            //console.log("Field "+elem.attr('name')+" is valid");
            var errorCount = $this.find('[data-invalid]').length;     
          });
          $this.bind("forminvalid.zf.abide", function(e,target) {
            //console.log("form is invalid");
          });

          $this.on("formvalid.zf.abide", function(ev,frm) {
            var formData;

            formData = 'action=loginFormAction&post_id=' + SiteParameters.post_id +'&form_index=' + formIndex +'&form_type=' + formType + '&' + $this.serialize();
            $.ajax({
              url: SiteParameters.ajax_url,
              type: 'POST',
              dataType: 'JSON',
              data: formData,
              beforeSend: function () {
                //console.log('beforeSend');               
              },
              success: function (data) {
                if (data.status === 'success') {
                  $('.login-form-' + formIndex).hide();
                  $('.login-form-' + formIndex).next('#thank-you-message').fadeIn(300);                
                  $(window).trigger('resize');
                  console.log(data.message);

                  if (data.redirect !== '') {
                    window.location.href = data.redirect;
                  }                  
                } else if (data.status === 'wrong') {
                  $('.login-form-' + formIndex + ' #login_pass').addClass('is-invalid-input');
                  $('.login-form-' + formIndex + ' .wrong-password').addClass('is-visible');
                } else {
                  console.log('success Error' + data.message);
                }
              },
              error: function(data) {
                console.log("Error: " + data.message);
                return false;
              },              
              complete: function() {
                //console.log('Complete');
              }
            });
            return false; 
          });
        });


        // Ansprechpartner Search Forms
        $('.ansprechpartner-search-form').each(function(idx, item) {
          var $this = $(this);
          var formIndex = $this.attr('data-form-index');
          var formType = $this.attr('data-form-type');

          $this.bind("submit",function(e) {
            e.preventDefault();
            //console.log("submit intercepted");
            return false;
          });

          $this.bind("invalid.zf.abide", function(ev,elem) {
            //console.log("Field "+elem.attr('name')+" is invalid");
            var errorCount = $this.find('[data-invalid]').length;       
          });

          $this.bind("valid.zf.abide", function(ev,elem) {
            //console.log("Field "+elem.attr('name')+" is valid");
            var errorCount = $this.find('[data-invalid]').length;     
          });
          $this.bind("forminvalid.zf.abide", function(e,target) {
            //console.log("form is invalid");
          });

          $this.on("formvalid.zf.abide", function(ev,frm) {
            var formData;

            formData = 'action=ansprechpartnerSearchFormAction&post_id=' + SiteParameters.post_id +'&form_index=' + formIndex +'&form_type=' + formType + '&' + $this.serialize();
            $.ajax({
              url: SiteParameters.ajax_url,
              type: 'POST',
              dataType: 'html',
              data: formData,
              beforeSend: function () {
                //console.log('beforeSend');               
              },
              success: function (data) {
                if (data) {
                  var parsedData = jQuery.parseJSON(data);
                  if (parsedData.output){
                    //$('.ansprechpartner-search-form-' + formIndex).hide();
                    $('.ansprechpartner-search-form-' + formIndex).next('.search-result').html(parsedData.output);                
                    //$(window).trigger('resize');
                  }                  
                } else {
                  console.log('success Error' + data.message);
                }
              },
              error: function(data) {
                console.log("Error: " + data.message);
                return false;
              },              
              complete: function() {
                //console.log('Complete');
              }
            });
            return false; 
          });
        });

        // fire initial resize
        $(window).trigger('resize');

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired  
       
      },
      resize: function() {

        var headerHeight = $('#main-header').outerHeight(true);
        var footerHeight = $("#footer-wrap").outerHeight(true);
        var windowHeight = $(window).height();
        var combinedHeight = windowHeight - headerHeight - footerHeight;


        // check for external links
        function checkForExternalLinks() {
          $('a').each(function() {
            var currentHref = this.href;

            if( location.hostname === this.hostname || !this.hostname.length ) {
              if (!$(this).hasClass('local')) {
                $(this).addClass('local');
              }
            } else {
              if (!$(this).hasClass('external')) {
                if (!$(this).hasClass('whatsapp-share-button')) {
                  $(this).addClass('external');
                  if ($('#external-link-popup').length) {
                    $(this).attr("data-open", 'external-link-popup' );
                    $(this).click( function( e ) {
                        e.preventDefault();
                        e.stopPropagation();
                        $('#external-link-popup').foundation('open');
                        $('#external-link-popup .leave-site').attr("href", currentHref );
                    });
                  }
                }
              }              
            }
          });
        }
        checkForExternalLinks();

        // ie object-fit fallback
        function checkForIE() {
          var userAgent = window.navigator.userAgent;
          var ieReg = /msie|Trident.*rv[ :]*11\./gi;
          var ie = ieReg.test(userAgent);
          
          if(ie) {

            $(".timeline-wrap .image-caption-label").addClass("ie-timeline-fix");
            $(".timeline-content-section .card-section").addClass("ie-timeline-fix");
            $(".timeline-content-section .image-module-wrap").addClass("ie-timeline-fix");
            $(".timeline-content-section .text-content-content").addClass("ie-timeline-fix");
            $(".divider").addClass("ie-menu-fix");
            $(".fuer-aerzte").addClass("ie-menu-doctor-fix");


            $(" .grid-item-image-wrap.image-wrap, .card-image-wrap, .post-head-section-image-wrap .image-wrap, .head-section-image-wrap .image-wrap, .image-section .image-wrap, .image-wrap.aspect-ratio").each(function () {
              var $container = $(this);
              var imgUrl = $container.find("img").prop("src");
              var imgPos = $container.find("img").attr("data-object-position");
              if (imgUrl) {
                $container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("ie-object-fit");
                if (imgPos) {
                  $container.css("background-position", imgPos);
                }                
              }
            });
          }                          
        }
        checkForIE();       

        // Sliders
        var resizeSliders = null;

        clearTimeout(resizeSliders);
        resizeSliders = setTimeout(
          function() {
            function renderCounter(slick,carouselId) {
              var slidesToShow;
              if(slick.activeBreakpoint!==null){
                slidesToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToShow;
              } else {
                slidesToShow = slick.options.slidesToShow;
              }
    
              if($('#'+carouselId).parents('.slider-wrapper').find('.slick-next').length>0){
                $('#'+carouselId).parents('.slider-wrapper').find('.counter').html( (slick.currentSlide+slidesToShow)/slidesToShow +' / ' + Math.ceil((slick.slideCount)/slidesToShow));
                $('#'+carouselId).parents('.slider-wrapper').find('.slider-control').show();
              } else {
                $('#'+carouselId).parents('.slider-wrapper').find('.counter').html('');
                $('#'+carouselId).parents('.slider-wrapper').find('.slider-control').hide();
              }
            } 
    
            $('.common-slider').each(function (idx, item) {
              if ($(this).hasClass('slick-initialized')) {
                  $(this).slick('resize');
              } else {
                var carouselId = "carousel_" + idx;
                this.id = carouselId;
                $(this).attr('data-counter','carousel_slick-control_' + idx);
                $(this).next('.slider-control').attr('id','carousel_slick-control_' + idx);
                
                $(this).on('init', function(event, slick) {
                  //console.log('init');
                });
    
                $(this).on('setPosition', function(event, slick) {
                  //console.log('setPosition');
                  renderCounter(slick,carouselId);
                });
    
                $(this).on('afterChange', function(event, slick, currentSlide){
                  //console.log('afterChange');
                });  

                $(this).on('edge', function(event, slick, direction){
                  //console.log('edge was hit');
                });                          
    
                $(this).slick();

              }
            });



              // medium down Slider
            $('.medium-down-slider').each(function (idx, item) {
              if ($(this).hasClass('slick-initialized')) {
                if (Foundation.MediaQuery.current === 'medium' || Foundation.MediaQuery.current === 'small') {
                  $(this).slick('resize');
                } else {
                  $(this).slick('unslick');          
                }
              } else {
                if (Foundation.MediaQuery.current === 'medium' || Foundation.MediaQuery.current === 'small') {
                  idx = idx + 40;
                  var carouselId = "carousel_" + idx;
                  this.id = carouselId;           
      
                  $(this).slick({
                    infinite: false,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    vertical: true,
                    verticalSwiping: true,
                    verticalScrolling: true
                  });                                       
                }      
              }
            });             

          },
          400
        );              

        // Load ajax posts
        function load_ajax_posts () {
            var settingsKey = $(this).attr('data-posts-selector-index');
            var currentPostID = $(this).attr('data-posts-selector-current-post-id');

            var currentHowManyPosts = $(this).attr('data-posts-selector-how-many-posts');
            var currentNumRowsMobile = $(this).attr('data-posts-selector-num-rows-mobile');
            var currentNumRowsDesktop = $(this).attr('data-posts-selector-num-rows-desktop');

            var currentTeaserStyle = $(this).attr('data-posts-selector-current-teaser-style');

            var currentCustomVars = $(this).attr('data-posts-selector-custom-vars');
      
            var currentPostCount = $(this).find('.load-more').attr('data-load-more-current-post-count');
            var currentOffset = $(this).find('.load-more').attr('data-load-more-current-offset');
            
            var currentPostsPerPage = -1; 
            var currentMediaquery = '';

            var noImages = false;

            if (currentTeaserStyle === 'abstract-teasers') {
              noImages = true;
            }
            
            if (currentHowManyPosts !== 'all') {
              if (Foundation.MediaQuery.is('small only')) {
                  currentPostsPerPage = currentNumRowsMobile;
              }
              if (Foundation.MediaQuery.is('medium only')) {
                  currentPostsPerPage = currentNumRowsDesktop * 3;
              }
              if (Foundation.MediaQuery.is('large only')) {
                  currentPostsPerPage = currentNumRowsDesktop * 3;
              }
              if (Foundation.MediaQuery.atLeast('xlarge')) {
                  currentPostsPerPage = currentNumRowsDesktop * 3;
              }
              if (Foundation.MediaQuery.atLeast('xxlarge')) {
                  currentPostsPerPage = currentNumRowsDesktop * 4;
              }

              if (currentTeaserStyle === 'event-teasers' || currentTeaserStyle === 'abstract-teasers') {
                if (Foundation.MediaQuery.is('small only')) {
                    currentPostsPerPage =  currentNumRowsMobile;
                } 
                if (Foundation.MediaQuery.is('medium only')) {
                    currentPostsPerPage =  currentNumRowsDesktop;
                }
                if (Foundation.MediaQuery.atLeast('large')) {
                    currentPostsPerPage = currentNumRowsDesktop;
                }
              }
            }

            if (currentHowManyPosts === 'home') {
              if (Foundation.MediaQuery.is('small only')) {
                  currentPostsPerPage =  -1;
              } 
              if (Foundation.MediaQuery.is('medium only')) {
                  currentPostsPerPage =  -1;
              }
              if (Foundation.MediaQuery.atLeast('large')) {
                  currentPostsPerPage = currentNumRowsDesktop;
              }
            }
      
            if (currentHowManyPosts === 'home-first-big') {
              if (Foundation.MediaQuery.is('small only')) {
                  currentPostsPerPage = 4;
              }
              if (Foundation.MediaQuery.is('medium only')) {
                  currentPostsPerPage = 4;
              }
              if (Foundation.MediaQuery.is('large only')) {
                  currentPostsPerPage = 4;
              }
              if (Foundation.MediaQuery.atLeast('xlarge')) {
                  currentPostsPerPage = 4;
              }
              if (Foundation.MediaQuery.atLeast('xxlarge')) {
                  currentPostsPerPage = 5;
              }
            }      
            

            if (Foundation.MediaQuery.is('small only')) {
                currentMediaquery = 'small';
            }
            if (Foundation.MediaQuery.is('medium only')) {
                currentMediaquery = 'medium';
            }
            if (Foundation.MediaQuery.is('large only')) {
                currentMediaquery = 'large';
            }
            if (Foundation.MediaQuery.atLeast('xlarge')) {
                currentMediaquery = 'xlarge';
            }      
            if (Foundation.MediaQuery.atLeast('xxlarge')) {
                currentMediaquery = 'xxlarge';
            }             
            
            if ( !($('.posts-selector-multiple-' + settingsKey).hasClass('loader-loading')) ) {
            
              $.ajax({
                type: "GET",
                url: SiteParameters.ajax_url,
                dataType: 'html',
                data: ({
                  action: 'load_responsive_posts',
                  currentsettingskey: settingsKey,
                  currentpostid: currentPostID,
                  currentpostsperpage: currentPostsPerPage,
                  currentcustomvars: currentCustomVars,
                  currentmediaquery: currentMediaquery,
                  currentpostcount: currentPostCount,
                  currentoffset: currentOffset
                }),
                beforeSend : function () {
                  //console.log("beforeSend");
                  $('.posts-selector-multiple-' + settingsKey).addClass('loader-loading');
                },              
                success: function(data) {
                  //console.log("success");
                  if(data) {
                    if ( $('#load-more-' + settingsKey).length > 0 ) {
                      //console.log("load-more.length");
                      $('#load-more-' + settingsKey).closest('.more-button-wrap').replaceWith("<div class='new-data grid-x grid-margin-x' style='position: relative;'>" + data + "</div>");                     
                      $('.posts-selector-multiple-' + settingsKey).find('.new-data').append( $('.posts-selector-multiple-' + settingsKey).closest('section').find('.load-wrapper') );
                      $('.posts-selector-multiple-' + settingsKey).closest('section').find('.load-wrapper').removeClass('loading-complete');
                    } else {
                      $('.posts-selector-multiple-' + settingsKey).prepend(data);
                    }

                    if ( $('#hidden-all-post-count-' + settingsKey).length > 0 && $('#all-post-count-' + settingsKey).length > 0) {
                      var currentAllPostCount = $('#hidden-all-post-count-' + settingsKey).attr('data-load-more-all-post-count');                     
                      $('#all-post-count-' + settingsKey).html(currentAllPostCount);

                      if (currentAllPostCount === '0' || currentAllPostCount === 'undefined') {
                        $('.posts-selector-multiple-' + settingsKey).closest('section').addClass('hide-section');
                      }

                    }
                    var itemCount = 0;
                    if (currentPostCount) {
                      itemCount = currentPostCount;
                    }
                    var itemLength = $('.posts-selector-multiple-' + settingsKey + ' .post-item').length;
                    $('.posts-selector-multiple-' + settingsKey + ' .post-item').each(function(){
                      var $this = $(this);
                      if (itemLength > 1) {
                        $this.attr("data-equalizer-watch", 'posts-selector-' + settingsKey ); 
                      }
                      var $cardImage = $this.find('.card-image');
                      $cardImage.on("replaced.zf.interchange", function() {
                        $cardImage.on('load', function() {
                            itemCount++;
                            if (itemCount === itemLength) {
                              $('.posts-selector-multiple-' + settingsKey).closest('section').find('.load-wrapper').addClass('loading-complete');
                              if ( $('.posts-selector-multiple-' + settingsKey).closest('section').find('.load-wrapper').parent().hasClass('new-data') ) {
                                $('.posts-selector-multiple-' + settingsKey).closest('section').find('.load-wrapper').unwrap();
                              }
                            }                            
                        });                     
                      });  
 
                      $this.find('.card-image').not('[data-resize]').foundation();                     
                    }); 

                    if (Foundation.MediaQuery.current === 'medium' || Foundation.MediaQuery.current === 'small') {
                      if ( $('.posts-selector-multiple-' + settingsKey).hasClass('medium-down-slider') ) {
                        $('.posts-selector-multiple-' + settingsKey + ' .post-item').each(function(){
                          var $this = $(this);                        
                          $('.posts-selector-multiple-' + settingsKey).slick('slickAdd',$this); 
                        });   
                        var $footerSlide = $('#footer-wrap');
                        $('.posts-selector-multiple-' + settingsKey).slick('slickAdd',$footerSlide);                    
                      }
                    } else {
                      $('#footer-wrap').insertAfter( $('.wrap.container') );
                    }

                    if ( $('.posts-selector-multiple-' + settingsKey).hasClass('posts-selector-equalize') ) {
                      Foundation.reInit('equalizer');                     
                    }

                    checkForExternalLinks();
                    checkForIE();

                  } else {
                    console.log("no data");
                  }
                },
                error: function(data) {
                  console.log("Error: " + data);
                  return false;
                },
                complete:   function() {  
                  //console.log("complete");  
                  $('.posts-selector-multiple-' + settingsKey).removeClass('loader-loading');
                  if (noImages) {
                    $('.posts-selector-multiple-' + settingsKey).closest('section').find('.load-wrapper').addClass('loading-complete');
                    if ( $('.posts-selector-multiple-' + settingsKey).closest('section').find('.load-wrapper').parent().hasClass('new-data') ) {
                      $('.posts-selector-multiple-' + settingsKey).closest('section').find('.load-wrapper').unwrap();
                    }                    
                  }   

                  $('.home-section.first-big').each(function(){
                    var $postItems = $(this).find('.post-items');
                    var $firstPostItem = $(this).find('.post-items .post-item').first();
                    var $otherPostItems = $(this).find('.post-item:not(:first-child)');
                    var $sidebarTeaserWrap = $(this).find('.sidebar-teaser-wrap');                    
                    $(this).find( '.more-button-wrap' ).css('display', 'none');
                    
          
                    if (Foundation.MediaQuery.atLeast('large')) {
                      $otherPostItems.css('margin-bottom', '0');
                      $sidebarTeaserWrap.insertAfter( $firstPostItem ); 
                      if ($(this).find( '.sidebar-teaser-wrap .sidebar-teaser' ).length === 1) {
                        $(this).find( '.sidebar-teaser-wrap .teaser-items' ).css('height', '100%');
                        $firstPostItem.find('.card-image-wrap').css('height', '100%');                   
                      }                                      
                    } else {
                      if (Foundation.MediaQuery.current === 'medium') {
                        $firstPostItem.css('width', 'calc(100% - 1.875rem)').css('height', '460px');
                      }
                      if (Foundation.MediaQuery.current === 'small') {
                        $firstPostItem.css('width', '100%').css('height', '460px');
                      }
                      $(this).find( '.sidebar-teaser-wrap .teaser-items' ).css('height', 'auto');
                      $sidebarTeaserWrap.insertAfter( $postItems );
                    }      
                  });

                  // Trigger event after the posts are loaded
                  var parentSectionIndex = $('.posts-selector-multiple-' + settingsKey).closest('section').attr('data-section-index');
                  var loadResponsivePostsCompleteEvent = new CustomEvent('loadResponsivePostsComplete', {
                    'detail': {"parentsectionindex":parentSectionIndex}
                  });
                  document.dispatchEvent(loadResponsivePostsCompleteEvent);                         
                }           
              });
            }
        }            

        var resizeMorePosts = null;

        clearTimeout(resizeMorePosts);
        resizeMorePosts = setTimeout(
          function() {
            $('.posts-selector-multiple .post-item').each(function(){
              $(this).remove(); 
            });  
            $('.posts-selector-multiple .load-more').each(function(){
              $(this).remove(); 
            });                      
            $.each($('.posts-selector-multiple'), load_ajax_posts);
            //console.log("resizeMorePosts fired");            
          },
          400
        ); 
              
        $(".posts-selector-multiple").on('click', 'button.load-more', function() {
          var currentParent = $(this).closest('.posts-selector-multiple');
          $.each(currentParent, load_ajax_posts);
        });        

        document.addEventListener('loadResponsivePostsComplete', function(e) {
          //console.log("loadResponsivePostsComplete parentSectionIndex: " + e.detail.parentsectionindex);     
        }, false); 


        if (Foundation.MediaQuery.atLeast('xlarge')) {
          $('.all-filter-wrap').insertAfter( $('.all-filter-button-wrap') );
          $('.all-filter-wrap').removeClass('reveal');
          $('.all-filter-wrap').removeAttr('data-reveal');    
          if ($('.all-filter-wrap[data-yeti-box]').length) {
            $('.all-filter-wrap[data-yeti-box]').foundation('destroy');
            $('.all-filter-wrap').removeAttr('data-yeti-box');
            $('.all-filter-wrap').removeAttr('data-resize');
            $('.all-filter-wrap').removeAttr('data-events');
            $('.all-filter-wrap').removeAttr('aria-hidden');
            $('.all-filter-wrap').removeAttr('tabindex');
            $('.all-filter-wrap').removeAttr('style');
          }        
        } else {
          $('.all-filter-wrap').addClass('reveal');
          $('.all-filter-wrap').attr('data-reveal','');
          $('.all-filter-wrap').not('[data-yeti-box]').foundation();    
        }

        function getUrlParameter(name) {
          name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
          var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
          var results = regex.exec(location.search);
          return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }

        function setUrlParameter(key, value) {
            var baseUrl = [location.protocol, '//', location.host, location.pathname].join(''),
                urlQueryString = document.location.search,
                newParam = key + '=' + value,
                params = '?' + newParam;
            // If the "search" string exists, then build params from it
            if (urlQueryString) {
                var updateRegex = new RegExp('([\?&])' + key + '[^&]*');
                var removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');
        
                if( typeof value === 'undefined' || value === null || value === '' ) { // Remove param if value is empty
                    params = urlQueryString.replace(removeRegex, "$1");
                    params = params.replace( /[&;]$/, "" );
        
                } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
                    params = urlQueryString.replace(updateRegex, "$1" + newParam);
        
                } else { // Otherwise, add it to end of query string
                    params = urlQueryString + '&' + newParam;
                }
            }
            // no parameter was set, remove the question mark
            params = params === '?' ? '' : params;
            window.history.replaceState({}, "", baseUrl + params);
        }

        function recipe_ajax_load_more (buttonWrap, postsWrap, restartPosts) {
          var $current_button_wrap = buttonWrap;
          var $current_posts_wrap = postsWrap;
          var current_restart_posts = restartPosts;
          var currentPostsRelated = $current_posts_wrap.attr('data-posts-related');
          var currentPostsPerPage = $current_posts_wrap.attr('data-posts-per-page');          
          var urlParamsMorePosts = getUrlParameter('moreposts');
          var urlParamsGruppe = getUrlParameter('gruppe');
          var urlParamsArt = getUrlParameter('art');
          var urlParamsFokus = getUrlParameter('fokus');

          //console.log("urlParamsMorePosts: " + urlParamsMorePosts);
          //console.log("urlParamsGruppe: " + urlParamsGruppe);
          //console.log("urlParamsArt: " + urlParamsArt);
          //console.log("urlParamsFokus: " + urlParamsFokus);
          //console.log("CurrentrestartPosts: " + current_restart_posts);

          $.ajax({
            url : SiteParameters.ajax_url,
            type : 'GET',
            dataType: 'html',
            data: ({
              action: 'recipe_load_more',
              currentrestartposts: current_restart_posts,
              currentpostsrelated: currentPostsRelated,
              currentpostsperpage: currentPostsPerPage,
              currentmoreposts: urlParamsMorePosts,
              currentgruppe: urlParamsGruppe,
              currentart: urlParamsArt,
              currentfokus: urlParamsFokus,
              currentpostid: SiteParameters.post_id
            }),      
            beforeSend: function (xhr) {
              //$current_button_wrap.find('.button').text('Loading...');
              $current_posts_wrap.closest('.standard-load-more-wrap').find('.load-wrapper').removeClass('loading-complete');
            },
            success: function(data){
              if(data) { 
                var parsedData = jQuery.parseJSON(data);
                //console.log("CurrentPostCount: " + parsedData.currentPostCount);
                //console.log("CurrentFoundPosts: " + parsedData.currentFoundPosts);
                //console.log("CurrentShownPosts: " + parsedData.currentShownPosts);
                //console.log("CurrentFokusTermsFound: " + parsedData.currentFokusTermsFound);

                $('input.fokus-checkbox').removeClass('filtered');

                $.each(parsedData.currentFokusTermsFound, function(index, value) {
                  $('input.fokus-checkbox[value="' + value + '"]').addClass('filtered');                 
                });

                if ($('input.art-checkbox:checked').length) {
                  $('input.fokus-checkbox').each(function(){
                    if ($(this).hasClass('filtered')) {
                      $(this).removeAttr("disabled");
                      $(this).closest('label').removeClass("disabled");
                    } else {
                      $(this).prop("checked", false);
                      $(this).attr("disabled", true);
                      $(this).closest('label').addClass("disabled"); 
                    }        
                  });                  
                } else {
                  $('input.fokus-checkbox').removeAttr("disabled");
                  $('input.fokus-checkbox').closest('label').removeClass("disabled");                  
                }
                

                if ( parsedData.currentFoundPosts === 0 ){
                  $('.no-post-headline').removeClass('hide');
                } else {
                  $('.no-post-headline').addClass('hide');
                }

                if ( parsedData.currentFoundPosts === 1 ){
                  $('.single-post-headline').removeClass('hide');
                } else {
                  $('.single-post-headline').addClass('hide');
                }

                if ( parsedData.currentFoundPosts > 1 ){
                  $('.some-posts-headline').removeClass('hide').find('.num-posts-counter').html(parsedData.currentFoundPosts);
                } else {
                  $('.some-posts-headline').addClass('hide');
                }                

                if (current_restart_posts){
                  $current_posts_wrap.html(parsedData.output);
                } else {
                  $current_posts_wrap.append(parsedData.output);
                }              
      
                if ( parsedData.currentShownPosts >= parsedData.currentFoundPosts ){
                  $current_button_wrap.hide();
                } else {
                  $current_button_wrap.show();
                }

                $current_posts_wrap.closest('.standard-load-more-wrap').find('.load-wrapper').addClass('loading-complete');

              } else {
                $current_button_wrap.hide();
              }
            },
            error: function(data) {
              console.log("Error: " + data);
              return false;
            },
            complete: function() {    
              //console.log("Complete");                         
            }
          });
        }


        $('.standard-load-more-wrap').on('click', 'button.standard-load-more', function() {
          var currentPostsWrap = $(this).closest('.standard-load-more-wrap').find('.more-posts-wrap');
          var currentButtonWrap = $(this).closest('.more-button-wrap');
          var urlParamsMorePosts = getUrlParameter('moreposts');
          var parsedUPMP = parseInt(urlParamsMorePosts);
          var morePostsNum = 1;
          if ( typeof urlParamsMorePosts !== 'undefined' && urlParamsMorePosts !== null && !isNaN(parsedUPMP)) {
            morePostsNum = parsedUPMP + 1;
          }
          setUrlParameter('moreposts', morePostsNum);
          recipe_ajax_load_more(currentButtonWrap, currentPostsWrap);
        });

        window.addEventListener( 'popstate', function(e) {
          //e.preventDefault();
          var url = window.location.href;
          //console.log('url: ' + url);
          //console.log('state: ', e.state);
        });

        function updateURL() {
          if (history.pushState) {
            var currentGroup = $('#gruppe-search-dropdown option:selected').val();
            var params = '?gruppe=' + currentGroup;
            var data = {};
            var fdata = [];
            var blkstr = {};
            $('input[type="checkbox"]').each(function(i){
              var $this = $(this);
              var thisName = $(this).attr('name');
              var thisVal = $(this).val();
                if($(this).is(":checked")){
                    if(!data.hasOwnProperty(thisName)){
                        data[thisName] = [];
                    }
                    data[thisName].push(thisVal);
                }
            });
            
            $.each(data, function(k, v){
              if(!blkstr.hasOwnProperty(k)){
                blkstr[k] = [];
                params = params + '&' + k + '=';
              }              
              fdata[k] = [v.join(',')];
              params = params + fdata[k];
            });
    
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + params;
            window.history.pushState({path:newurl},'',newurl);
          }
        }

        // Check if any boxes are checked
        var anyBoxesChecked = false;

        $('.art-filter-wrap input[type="checkbox"]').each(function() {
            if ($(this).is(":checked")) {
                anyBoxesChecked = true;
                //console.log('art-checkbox-Val: ' + $(this).val());
            }
        });
        $('.fokus-filter-wrap input[type="checkbox"]').each(function() {
            if ($(this).is(":checked")) {
                anyBoxesChecked = true;
                //console.log('fokus-checkbox-Val: ' + $(this).val());
            }
        });        

        // initial state
        if ($('.recipe-filter-section').length) {
          if ( ($('#gruppe-search-dropdown option:selected').val() === 'erwachsene-jugendliche') && anyBoxesChecked === false && getUrlParameter('moreposts') === '' ) {
            updateURL();
            $('#load-more-recipes-wrap').html('');
            $('.filter-reset-button').hide();
            recipe_ajax_load_more($('#load-more-recipes-button-wrap'), $('#load-more-recipes-wrap'));
          } else {
            $('#load-more-recipes-wrap').html('');
            recipe_ajax_load_more($('#load-more-recipes-button-wrap'), $('#load-more-recipes-wrap'), true);
          }  
        }      

        if ($('.post-related-section #load-more-recipes-wrap').length) {
          $('#load-more-recipes-wrap').html('');
          recipe_ajax_load_more($('#load-more-recipes-button-wrap'), $('#load-more-recipes-wrap'), true);
        } 

        $('#gruppe-search-dropdown').on('change', function() {
          if ($('#gruppe-search-dropdown option:selected').val() !== 'erwachsene-jugendliche') {
            $('input.filter-checkbox').prop("checked", false);
            $('input.filter-checkbox').attr("disabled", true);
            $('.art-filter-wrap').hide();
            $('.fokus-filter-wrap').hide();             
          } else {
            $('.art-filter-wrap').show();
            $('.fokus-filter-wrap').show();              
            $('input.filter-checkbox').removeAttr("disabled");
          }
          $('.filter-reset-button').show();
          updateURL();
          recipe_ajax_load_more($('#load-more-recipes-button-wrap'), $('#load-more-recipes-wrap'), true);
        });   

        $('.art-filter-wrap .art-checkbox').on('change', function() {
          //console.log('changed art-checkbox-Val: ' + $(this).val());
          $('.filter-reset-button').show();
          updateURL();
          recipe_ajax_load_more($('#load-more-recipes-button-wrap'), $('#load-more-recipes-wrap'), true);
        });

        $('.fokus-filter-wrap .fokus-checkbox').on('change', function() {
          //console.log('changed fokus-checkbox-Val: ' + $(this).val());
          $('.filter-reset-button').show();
          updateURL();
          recipe_ajax_load_more($('#load-more-recipes-button-wrap'), $('#load-more-recipes-wrap'), true);
        }); 

        $('.filter-reset-button').on('click', function() {            
          $('input.filter-checkbox').prop("checked", false);
          $('#gruppe-search-dropdown').val('erwachsene-jugendliche').change();
          $('.filter-reset-button').hide();
        });


        // Hoehen syncen

        function equalHeight(group) {
          var tallest = 0;
          group.css('height','auto'); //otherwise only works once!
          group.each(function() {
            var thisHeight = $(this).height();
            if(thisHeight > tallest) {
              tallest = thisHeight;
            }
          });
          group.height(tallest);
        }


        //if($(window).width() > 725){
        //  //  equalHeight($('.teaser-section .teaser-text'));
        //  $('.grid-items').each(function () {
        //    equalHeight($(".grid-item-text-wrap", this));
        //  });
        //}
        //else {
//
//
        //  $('.grid-item-headline').css({'height': 'auto'});
        //}

        // set height of head-section style-full-size
        $('.head-section.style-full-size').each(function(){
          var headSectionImageHeight = $(this).find('.image-wrap').outerHeight(true); 
          var headSectionHeadlineWrapHeight = $(this).find('.section-headline-wrap').outerHeight(true);
          var scrollDownButtonHeight = $(this).find('.scrolldown-button').outerHeight(true);
          var imageHeadSectionMinHeight = headSectionImageHeight + headSectionHeadlineWrapHeight + scrollDownButtonHeight + 120;

          if ((Foundation.MediaQuery.atLeast('large')) && (windowHeight < 1000)) {
            $(this).find('.image-wrap').css({'height': windowHeight - headerHeight});
            $(this).find('.scrolldown-button').css({'display': 'block'});
            if ($(this).find('.wp-caption-text').length) {
              var captionTextHeight = $(this).find('.wp-caption-text').outerHeight(true);
              $(this).find('.scrolldown-button').css({'bottom': captionTextHeight + 20 + "px"});
            }          
          } else {
            $(this).find('.image-wrap').css({'height': 'auto'});
            $(this).find('.scrolldown-button').css({'display': 'none'});
          } 
        });         

        // set height of head-section style-medium-size
        $('.head-section.style-medium-size').each(function(){
          var styleMediumHeight = 400;
          var headSectionImageWrap = $(this).find('.image-wrap');
          var headSectionImageHeight = $(this).find('.image-wrap').outerHeight(true);

          if (Foundation.MediaQuery.atLeast('large')) {
            if (headSectionImageWrap.hasClass('aspect-ratio')) {
              $(this).find('.image-wrap').css({'height': styleMediumHeight});
            }
            if (headSectionImageWrap.hasClass('auto-ratio')) {
              $(this).find('.image-wrap').css({'height': 'auto'});
            }            
            $(this).find('.scrolldown-button').css({'display': 'none'});          
          } else {
            $(this).find('.image-wrap').css({'height': 'auto'});
            $(this).find('.scrolldown-button').css({'display': 'none'});
          } 
        }); 

        $('.head-section').each(function(){
          // move section-headline-bottom-content-wrap
          var $sectionHeadlineWrap = $(this).find('.section-headline-wrap');
          var $sectionHeadlineBottomContentWrap = $(this).find('.section-headline-bottom-content-wrap');

          if (Foundation.MediaQuery.atLeast('large')) {
            if ($(this).next('.section-headline-bottom-content-section').length) {
              $sectionHeadlineBottomContentWrap = $(this).next('.section-headline-bottom-content-section').find('.section-headline-bottom-content-wrap');
              $sectionHeadlineWrap.append( $sectionHeadlineBottomContentWrap );
              $(this).next('.section-headline-bottom-content-section').remove();
              $(this).next('.custom-divider-block').remove();
            } else {
              if ($sectionHeadlineBottomContentWrap.length) {
                $sectionHeadlineWrap.append( $sectionHeadlineBottomContentWrap );
              }
            }                   
          } else {
            if ($(this).next('.section-headline-bottom-content-section').length === 0) {
              if ($sectionHeadlineBottomContentWrap.length) {
                var $newSection = $( "<section class='section-headline-bottom-content-section'><div class='section-inner'></div></section>" );
                var $newDivider = $( "<div class='divider-block custom-divider-block'></div>" );
                $newSection.find('.section-inner').append( $sectionHeadlineBottomContentWrap );
                $newSection.insertAfter( $(this) );
                $newDivider.insertAfter( $newSection );
              }
            }
          } 

          // adjust red line width
          var $sectionHeadlineRedLine = $(this).find('.red-line');
          var $sectionPreHeadline = $(this).find('.section-pre-headline-span'); 
          var sectionPreHeadlineWidth = $sectionPreHeadline.innerWidth();   
          if (Foundation.MediaQuery.atLeast('large')) {
            if (sectionPreHeadlineWidth > 400) {
              $sectionHeadlineRedLine.css({'max-width': sectionPreHeadlineWidth});
            }  
          } else {
            if (sectionPreHeadlineWidth > 250) {
              $sectionHeadlineRedLine.css({'max-width': sectionPreHeadlineWidth});
            }             
          }     
  
        });   

        // move cart-menu-item
        if (Foundation.MediaQuery.atLeast('xlarge')) {
          $( ".cart-menu-item" ).appendTo( $( ".primary-nav" ) );
        } else {
          $( ".cart-menu-item" ).appendTo( $( ".home-nav" ) );
        }  

        // position search box on location-map-section
        if (Foundation.MediaQuery.atLeast('large')) {
          var lmsWidth = $('#top-menu').innerWidth();         
          $(".location-map-section .section-inner").css({'width': lmsWidth});  
        } else {
          $(".location-map-section .section-inner").css({'width': 'auto'});          
        }                     

        // table width
        $('table').each(function(i) {
          var colCount = 0;
          var $thisTable = $(this);
          $thisTable.find('td').each(function () {
            if (Foundation.MediaQuery.is('small only')) {
              $(this).removeAttr('width');
            }
          });          
          $thisTable.find('tr:nth-child(1) td').each(function () {
            if ($(this).attr('colspan')) {
                colCount += +$(this).attr('colspan');
            } else {
                colCount++;
            }
          });
          if (colCount <= 2) {
             $thisTable.addClass('two-cols');
          }
          if (colCount === 3) {
             $thisTable.addClass('three-cols');
          }          
        }); 

        // Sticky footer
        function stickyFooter() {
          if (Foundation.MediaQuery.atLeast('large')) {
            $("main.main").css('min-height', combinedHeight + "px");
          } else {
            $("main.main").css('min-height', "100vh");          
          }                    
        }

        if ($( "#footer-wrap" ).length) {
          //stickyFooter();
        }       

      },
      windowload: function() {
        //console.log('loaded');

        if ($( ".posts-selector-multiple" ).length) {          
          $('html').addClass('window-loaded');
        } else {
          $('html').addClass('window-loaded');
        }     
        if ($( '[data-equalizer]' ).length) {  
          Foundation.reInit('equalizer');  
        } 


        // Firefox interchange Bugfix
        function checkForFF() {
          var userAgent = navigator.userAgent;
          
          if(userAgent.indexOf("Firefox") > -1) {
            //console.log('FF');
         
            if ($( ".teaser-grid-section" ).length) {          
              Foundation.reInit($('.grid-item-image'));
            }
            if ($( ".sidebar-teaser" ).length) {          
              Foundation.reInit($('.sidebar-teaser-image'));
            }          
            if ($( ".post-head-section" ).length) {          
              Foundation.reInit($('.post-head-section-image'));
            }
            if ($( ".head-section" ).length) {          
              Foundation.reInit($('.head-section-image'));
            }
            
          }                          
        }
        checkForFF();         

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      },
      resize: function() {

      },
      windowload: function() {

      }
    },
    'page_template_template_layouts': {
      init: function() {

      },
      finalize: function() {

      },
      resize: function() {

      },
      windowload: function() {

      }
    }    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
        //UTIL.fire(classnm, 'resize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      //UTIL.fire('common','resize');
    },
    resizeEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'resize');
      });

      UTIL.fire('common','resize');

    },
    windowLoadEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm, 'windowload');
      });

      UTIL.fire('common','windowload');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on("load",UTIL.windowLoadEvents);

  // Resize Events
  var trackWindowWidth = $(window).width();

  $(window).resize(function (trackResizeEvent) {
      //console.log(!trackResizeEvent.isTrigger ? ' resize' : ' triggerd');
  
      // Check window width has actually changed and it's not just iOS triggering a resize event on scroll || the resize event was caused by $(window).trigger('resize');
      if (($(window).width() !== trackWindowWidth) || trackResizeEvent.isTrigger) {
          // Update the window width for next time
          trackWindowWidth = $(window).width();

          UTIL.resizeEvents();
      }  
  });


})(jQuery); // Fully reference jQuery after this point.

